<template lang="pug">
Navbar
.padding-top
  .container.d-none.d-md-block
    nav(aria-label='breadcrumb')
    ol.breadcrumb
      li.breadcrumb-item
        router-link.text-black.text-decoration-none(to='home') Home
      li.breadcrumb-item.active(aria-current='page') Careers
  .pb-4.pb-md-0
    HeadingLayout(:title="title", :desc="desc")

  .bg-background.py-5.text-center
    .container
      .row
        .fs-3.fw-bold.heading.pb-4 Our culture values
        template(v-for="career in careers")
          .col-md-3.mb-5.mb-md-0
            img.d-block.mx-lg-auto.img-fluid(:src="'/images/career/' + career.image")
            .fs-4.career-title.fw-bold.pt-3 {{ career.title }}
            .career-desc {{ career.desc }}
  .container.py-4
    .row
      .col-12
        .fs-3.fw-bold.heading.pb-4.text-center Current openings
        table.table.text-center
          thead
            tr
              th Job title
              th Department
              th Posted on
              th Employment type
          tbody
            tr
              td Backend developer
              td Engineering
              td 1 Dec, 2021
              td Full time
            tr
              td Backend developer
              td Engineering
              td 1 Dec, 2021
              td Full time

Footer
</template>

<script>
import Navbar from "../components/TheNavbar";
import Footer from "../components/TheFooter";
import HeadingLayout from "../layout/HeadingLayout";
export default {
  name: "Career",
  components: { HeadingLayout, Footer, Navbar },
  data() {
    return {
      title: "Careers",
      desc: "Join the company where the employees thrive",
      careers: [
        {
          title: "Be better",
          desc: "We always seek for improvement and aim to become better version of ourselves.",
          image: "1.png",
        },
        {
          title: "Think big, move fast",
          desc: "We encourage the team to try things that have never been done before. It is okay to fail as long as we learn.",
          image: "2.png",
        },
        {
          title: "Customer obsession",
          desc: "Every decision we make has our customers in mind. We strive to deliver an integrated experience.",
          image: "3.png",
        },
        {
          title: "Do the right thing",
          desc: "We act with integrity, speak candidly, listen attentively and treat others respectfully.",
          image: "4.png",
        },
      ],
    };
  },
};
</script>
